// React
import { useState, useEffect } from "react";
import "./IssueRecapHomePage.scss";
import {
  getAPI,
  postAPI,
  downloadReportDocuments,
} from "../../services/CepApiServices";
import { useLocation } from "react-router-dom";
import { getUserMailID } from "../../utils/token";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import ConfirmationModal from "../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../utils/AppString";
import IssueRecapStandardOffer from "./IssueRecapStandardOffer/IssueRecapStandardOffer";
import IssueRecapBaseCharterParty from "./IssueRecapBaseCharterParty/IssueRecapBaseCharterParty";
import IssueRecapRiderClause from "./IssueRecapRiderClause/IssueRecapRiderClause";
import OfferTimeModal from "../NegotiationOfferPages/StandardOffer/OfferNegotiationCM/offerTimeModal";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserRole } from "../../utils/token";
import IssueRecapSelectionModal from "./IssueRecapSelectionModal";
import WarningModalPopup from "../../components/WarningModalPopup/WarningModalPopup";

interface IPROPS {
  indicationRequestId?: any;
  tabDetails?: any;
  reRenderApiFunc?: any;
  data?: any;
  recapStatus?: any;
  accessType?: any;
  isInitialRunningRecap?: any;
  isEditing?: any;
  mainInfoData?: any;
  isPrefilledCondition?: any;
  extendedContractIDBCP?:any;
}

const IssueRecapHomePage = ({
  indicationRequestId,
  tabDetails,
  reRenderApiFunc,
  data,
  recapStatus,
  accessType,
  isEditing,
  isInitialRunningRecap,
  mainInfoData,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalOpenCleanRecap, setmodalOpenCleanRecap] = useState(false);
  const [modalAckOpen, setModalAckOpen] = useState(false);
  const [mainResponse, setMainResponse] = useState<any>([]);
  const [riderClauseResponse, setRiderClauseResponse] = useState<any>([]);
  const [extendedRiderClauseResponse, setExtendedRiderClauseResponse] = useState<any>([]);
  const [standardTermResponse, setStandardTermResponse] = useState<any>([]);
  const [BCPResponse, setBCPResponse] = useState<any>([]);
  const [enableIssueRecapBCP, setEnableIssueRecapBCP] = useState<any>(false);
  const [acknowledgeBtnVO, setAcknowledgeBtnVO] = useState<any>(false);
  const [liftSubsBtn, setLiftSubsBtn] = useState<any>(false);
  const [cleanRecapBtn, setCleanRecapBtn] = useState<any>(false);
  const [cleanRecapAcknowledgeBtn, setCleanRecapAcknowledgeBtn] =
    useState<any>(false);
  const [modalAckCleanRecapOpen, setModalAckCleanRecapOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState<any>(false);
  const [liftSubsTimeModal, setliftSubsTimeModal] = useState(false);
  const [confirmationLiftSubsModal, setConfirmationLiftSubsModal] =
    useState(false);
  const [voLiftSubConfirmationModal, setVoLiftSubConfirmationModal] =
    useState(false);
  const [id, setId] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isIssueRecapStandardOfferTab, setIsIssueRecapStandardOfferTab] =
    useState(true);
  const [isIssueRecapBCP, setIsIssueRecapBCPTab] = useState(false);
  const [isIssueRecapRiderClauseTab, setIsIssueRecapRiderClauseTab] =
    useState(false);
  const [userRole, setUserRole] = useState();
  const [messageStatus, setMessageStatus] = useState("");
  const [displayDwnldBtn, setDisplayDwnldBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [runningRecapInfo, setRunningRecapInfo] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [currentStateType, setCurrentStateType] = useState("");
  const [cmLiftSubsStatus, setCMLiftsSubsStatus]=useState(false);
  const location = useLocation();
  const documentId = location?.state?.documentId;
  const UID = getUserMailID();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userTypeFromURL = params.get("user");
  const checkWriteAccess = accessType === "Write" ? true : false;
  useEffect(() => {
    getIssueRecapData(recapStatus);
    fetchToken();
  }, [recapStatus]);
  const initialAPICall = async () => {
    setIsLoading(true);
    const response = await getAPI(
      `Negotiation/GetStandardTermRunningRecapAsync/${indicationRequestId}`
    );
    if (response) {
      setStandardTermResponse(response?.standardTemplateMainTerms);
      setMainResponse(response);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isInitialRunningRecap) {
      setIsIssueRecapStandardOfferTab(true);
      setIsIssueRecapBCPTab(false);
      setIsIssueRecapRiderClauseTab(false);
      initialAPICall();
    }
  }, [isInitialRunningRecap]);
  const fetchToken = async () => {
    const role = await getUserRole();
    setUserRole(role);
  };

  const getIssueRecapData = async (responseValue) => {
    setIsLoader(true);
    if (responseValue) {
      setIsLoader(false);
      setRunningRecapInfo(responseValue?.isDataAvilable);
      setCMLiftsSubsStatus(responseValue?.isCmInitiatedLiftSub);
    }
    setDisplayDwnldBtn(responseValue?.isDataAvilable);
    if (responseValue?.isDataAvilable && userRole == "CharteringManager") {
      if (!responseValue?.isCmInitiatedIssueRecap) {
        //Display Issue Recap button
        setEnableIssueRecapBCP(true);
      } else if (
        responseValue?.isCmInitiatedIssueRecap &&
        !responseValue?.isVoAcknowledgeIssueRecap
      ) {
        setMessageStatus(
          "Issue Recap submitted, waiting for VO acknowledgement"
        );
        setEnableIssueRecapBCP(false);
      } else if (
        responseValue?.isIssueRecapCompleted &&
        !responseValue?.isVoInitiatedLiftSub
      ) {
        setMessageStatus("Issue Recap completed");
      } else if (
        responseValue?.isIssueRecapCompleted &&
        responseValue?.isVoInitiatedLiftSub &&
        !responseValue?.isLiftSubCompleted
      ) {
        //display Lift Subs button
        setLiftSubsBtn(true);
        setMessageStatus("VO has lifted the subs");
      } else if (
        responseValue?.isIssueRecapCompleted &&
        responseValue?.isLiftSubCompleted &&
        !responseValue?.isCmInitiatedCleanRecap
      ) {
        //display clean recap btn for CM
        setMessageStatus("");
        setCleanRecapBtn(true);
        setLiftSubsBtn(false);
      } else if (
        responseValue?.isCmInitiatedCleanRecap &&
        !responseValue?.isVoAcknowledgeCleanRecap
      ) {
        setMessageStatus(
          "Clean recap initiated by CM, waiting for VO acknowledgement"
        );
        setCleanRecapBtn(false);
        setLiftSubsBtn(false);
      } else if (
        responseValue?.isCleanRecapCompleted &&
        responseValue?.isLiftSubCompleted &&
        responseValue?.isIssueRecapCompleted
      ) {
        setMessageStatus("Issue Recap, Lift Subs and Clean Recap completed");
        setCleanRecapBtn(false);
        setLiftSubsBtn(false);
        setEnableIssueRecapBCP(false);
      } else if (responseValue?.locked) {
        setLiftSubsBtn(false);
        setEnableIssueRecapBCP(false);
        setCleanRecapBtn(false);
        setMessageStatus(
          "Lift Subs Timeline has expired. Please request for extension. Once VO has accepted the timeline, Lift Subs button will be enabled"
        );
      }
    } else if (
      responseValue?.isDataAvilable &&
      (userRole == "VesselOwner" || userRole == "OwnerRepresentative")
    ) {
      if (
        responseValue?.isCmInitiatedIssueRecap &&
        !responseValue?.isIssueRecapCompleted
      ) {
        setAcknowledgeBtnVO(true);
        setMessageStatus("");
      } else if (
        responseValue?.isIssueRecapCompleted &&
        !responseValue?.isVoInitiatedLiftSub
      ) {
        //Display lift subs button
        setLiftSubsBtn(true);
        setAcknowledgeBtnVO(false);
        setMessageStatus("Issue recap completed, please initiate lift subs");
      } else if (
        responseValue?.isVoInitiatedLiftSub &&
        !responseValue?.isLiftSubCompleted
      ) {
        setMessageStatus(
          "Lift Subs initiated, waiting for CM to lift the subs"
        );
        setLiftSubsBtn(false);
        setAcknowledgeBtnVO(false);
      } else if (
        responseValue?.isLiftSubCompleted &&
        !responseValue?.isCmInitiatedCleanRecap
      ) {
        setMessageStatus(
          "Lift Subs completed, waiting for CM to initiate Clean Recap"
        );
        setLiftSubsBtn(false);
        setAcknowledgeBtnVO(false);
      } else if (
        responseValue?.isLiftSubCompleted &&
        responseValue?.isCmInitiatedCleanRecap &&
        !responseValue?.isCleanRecapCompleted
      ) {
        //Display Clean Recap button
        setMessageStatus("");
        setCleanRecapAcknowledgeBtn(true);
        setLiftSubsBtn(false);
      } else if (
        responseValue?.isCleanRecapCompleted &&
        responseValue?.isLiftSubCompleted &&
        responseValue?.isIssueRecapCompleted
      ) {
        setMessageStatus("Issue Recap, Lift Subs and Clean Recap completed");
        setLiftSubsBtn(false);
        setCleanRecapAcknowledgeBtn(false);
      }
    }
  };

  const displayCnfrmModal = (string: any) => {
    if (isEditing) {
      setShowWarningModal(true);
      setCurrentStateType(
        "Please submit the negotiation to Issue Clean Recap."
      );
    } else {
      string == "issue" ? setmodalOpen(true) : setmodalOpenCleanRecap(true);
    }
  };

  const showIssueRecapModal = () => {
    if (isEditing) {
      setShowWarningModal(true);
      setCurrentStateType(
        "Please submit the negotiation to Issue Initial Recap."
      );
    } else {
      if (mainInfoData?.enableIssueRecap) {
        setShowPopupModal(true);
      } else {
        setShowWarningModal(true);
        setCurrentStateType(
          "Issue recap will be initiated after moving  all the Main Terms ( optional - Charter Party ) to running recap and there should not be any revert next counter."
        );
      }
    }
  };

  const displayAcknwlgdeModal = (string: any) => {
    string == "Acknowledge"
      ? setModalAckOpen(true)
      : setModalAckCleanRecapOpen(true);
  };

  const displayLiftSubsCnfrmModal = () => {
    if (userRole == "CharteringManager") {
      setliftSubsTimeModal(false);
      setConfirmationLiftSubsModal(true);
    } else {
      if (isEditing) {
        setShowWarningModal(true);
        setCurrentStateType("Please submit the negotiation to Lift the Subs.");
      } else {
        setVoLiftSubConfirmationModal(true);
        setConfirmationLiftSubsModal(false);
      }
    }
  };

  const closeTimerModal = () => {
    setliftSubsTimeModal(false);
  };
  const submitInitialIssueRecap = async (data) => {
    const liftSubPayload = {
      indicationRequestId: Number(indicationRequestId),
      recapStatus: "Issue Recap",
      formatTypeId: data.formatTypeId,
      voLiftSubRequestDays: Number(data.voLiftSubRequestDays),
      cmLiftSubRequestDays: Number(data.cmLiftSubRequestDays),
      voLiftSubRequestDate: data.voLiftSubRequestDate,
      cmLiftSubRequestDate: data.cmLiftSubRequestDate,
    };
    const response = await postAPI(`Negotiation/SubmitRecap`, liftSubPayload);
    if (response !== true) {
      toast.error(`Action failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setShowPopupModal(false);
    } else {
      toast.success(`Submitted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setSuccessMsg(true);
      reRenderApiFunc();
      setShowPopupModal(false);
    }
  };
  const submitIssueRecap = async (type: string) => {
    const payLoad = {
      indicationRequestId: Number(indicationRequestId),
      recapStatus: type,

      initiatedByCM: userRole == "CharteringManager" ? true : false,
    };

    const response = await postAPI(`Negotiation/SubmitRecap`, payLoad);

    if (response.StatusCode) {
      toast.error(`Action failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      toast.success(`Submitted successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      setSuccessMsg(true);
      reRenderApiFunc();
    }
  };

  const saveOrCancelIssueRecap = (response: string) => {
    if (response == "Yes") {
      submitIssueRecap("Issue Recap");
      setmodalOpen(false);
    } else {
      setmodalOpen(false);
    }
  };

  const saveOrCancelAcknowledgementByVO = (response: string) => {
    if (response == "Yes") {
      submitIssueRecap("Issue Recap");
      setModalAckOpen(false);
    } else {
      setModalAckOpen(false);
    }
  };

  const saveOrCancelAckCleanRecapByVO = (response: string) => {
    if (response == "Yes") {
      submitIssueRecap("Clean Recap");
      setModalAckCleanRecapOpen(false);
    } else {
      setModalAckCleanRecapOpen(false);
    }
  };

  const saveOrCancelCleanRecap = (response: string) => {
    if (response == "Yes") {
      submitIssueRecap("Clean Recap");
      setmodalOpenCleanRecap(false);
    } else {
      setmodalOpenCleanRecap(false);
    }
  };

  const saveOrCancelLiftSubs = (response: string) => {
    if (response == "Yes") {
      submitLiftSubs();
      setConfirmationLiftSubsModal(false);
    } else {
      setConfirmationLiftSubsModal(false);
    }
  };

  const saveOrCancelVoLiftSub = (response: string) => {
    if (response == "Yes") {
      submitLiftSubs();
      setVoLiftSubConfirmationModal(false);
    } else {
      setVoLiftSubConfirmationModal(false);
    }
  };

  const submitLiftSubs = async () => {
    const response = await postAPI(
      `Negotiation/InitiateLiftSub?indicationId=${Number(indicationRequestId)}`
    );
    if (response) {
      toast.error(`${response.Message}`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      reRenderApiFunc();
      toast.success(`LiftSubs initiated successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };

  const getRecapDataOnTabChange = async (type: any) => {
    if (type === "standard") {
      initialAPICall();
    } else if (type === "bcp") {
      setIsLoading(true);
      let response:any;
      if(isPrefilledCondition) {
        response = await getAPI(
          `Negotiation/GetBaseCharterPartyMainClauseContent/` + extendedContractIDBCP + `/1`+`/`+ false+`/`+false+`/`+false+`/`+true
        );
      } else {
        response = await getAPI(
          `Negotiation/GetBaseCharterPartyMainClauseContent/` + indicationRequestId + `/1`+`/`+ false+`/`+true+`/`+false+`/`+false
        );
      }
      if (response) {
        setBCPResponse(response);
        setIsLoading(false);
      }
    } else if (type === "riderClause") {
      setIsLoading(true);
      let response: any;
      if(isPrefilledCondition) {
      response = await getAPI(
        `Negotiation/GetBasGetRiderClauseContractDocument/${extendedContractIDBCP}`+`/`+false +`/`+false+`/`+false+`/`+true
      );
      if (response) {
        setRiderClauseResponse(response);
        setIsLoading(false);
      }
    }
    else {
      response = await getAPI(
        `Negotiation/GetBasGetRiderClauseContractDocument/${indicationRequestId}`+`/`+false +`/`+true+`/`+false+`/`+false
      );
      if(response) {
        setRiderClauseResponse(response);
        setIsLoading(false);
      }
    }   
    }
  };

  const downloadRunningRecapData = async () => {
    notify("File download started", true);
    await downloadReportDocuments(
      `Negotiation/ExportRecapToWord/${indicationRequestId}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = cmLiftSubsStatus ? "Clean Recap which does not have subjects" : "Main Terms Recap";
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
        notify("File downloaded successfully", true);
      })
      .catch(() => {
        console.log("data is not coming");
      });
  };

  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };

  return (
    <>
      <div className="issue-recap-sub-section">
        {/* {runningRecapInfo && (
          <div className="issueRecap-header-section">
            <div className="left-content">
              <span className="icon">
                {" "}
                <McIcon icon="info-circle" />
              </span>
              <span className="text">Placeholder text here </span>
            </div>
          </div>
        )} */}
        <div className="subColoum">
          <div className="tab-components">
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                data-testid="standard-offer"
                type="button"
                onClick={() => {
                  setIsIssueRecapStandardOfferTab(true);
                  setIsIssueRecapBCPTab(false);
                  setIsIssueRecapRiderClauseTab(false);
                  getRecapDataOnTabChange("standard");
                }}
                className={`btn btn-primary ${
                  isIssueRecapStandardOfferTab && "active"
                }`}
              >
                Main Terms
              </button>
              <button
                data-testid="base-charter-party"
                type="button"
                onClick={() => {
                  setIsIssueRecapBCPTab(true);
                  setIsIssueRecapStandardOfferTab(false);
                  setIsIssueRecapRiderClauseTab(false);
                  getRecapDataOnTabChange("bcp");
                }}
                className={`btn btn-primary ${isIssueRecapBCP && "active"}`}
              >
                Charter Party
              </button>
              <button
                data-testid="rider-clause"
                type="button"
                onClick={() => {
                  setIsIssueRecapStandardOfferTab(false);
                  setIsIssueRecapBCPTab(false);
                  setIsIssueRecapRiderClauseTab(true);
                  getRecapDataOnTabChange("riderClause");
                }}
                className={`btn btn-primary ${
                  isIssueRecapRiderClauseTab && "active"
                }`}
              >
                Rider Clauses
              </button>
            </div>
          </div>
        </div>
        <div className="btn-section">
          <div className="btn-div-ir">
            {enableIssueRecapBCP && checkWriteAccess && (
              <McButton
                data-testid="issue-recap"
                label="Issue Initial Recap"
                onclick={() => {
                  showIssueRecapModal();
                }}
                fit="small"
              ></McButton>
            )}
            {liftSubsBtn && checkWriteAccess && (
              <McButton
                data-testid="lift-subs"
                label="Lift Subs"
                onclick={() => {
                  displayLiftSubsCnfrmModal();
                }}
                fit="small"
              ></McButton>
            )}
            {cleanRecapBtn && checkWriteAccess && (
              <McButton
                data-testid="clean-recap"
                label="Clean Recap"
                onclick={() => {
                  displayCnfrmModal("clean");
                }}
                fit="small"
              ></McButton>
            )}
            {cleanRecapAcknowledgeBtn && checkWriteAccess && (
              <McButton
                data-testid="acknowledge-clean-recap"
                label="Acknowledge Clean Recap"
                onclick={() => {
                  displayAcknwlgdeModal("Acknowledge clean recap");
                }}
                fit="small"
              ></McButton>
            )}
            {acknowledgeBtnVO && checkWriteAccess && (
              <McButton
                data-testid="acknowledge-issue-recap"
                label="Acknowledge Initial Issue Recap"
                onclick={() => {
                  displayAcknwlgdeModal("Acknowledge");
                }}
                fit="small"
              ></McButton>
            )}
          </div>
          {displayDwnldBtn && (
            <div className="download-btn-ir">
              <McIcon
                icon="tray-arrow-down"
                data-testid="open-download"
                onClick={downloadRunningRecapData}
                title="Export Recap Summary"
              ></McIcon>
            </div>
          )}
        </div>

        <div className="btn-section">
          <div className="error-msg-ir">{messageStatus}</div>
        </div>

        <div className="issueRecap-section">
          <div className="issueRecap-dataSection">
            {isLoading && (
              <div className="loader-div">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {isIssueRecapStandardOfferTab && (
              <IssueRecapStandardOffer
                indicationId={indicationRequestId}
                reRenderApiFunc={reRenderApiFunc}
                submitData={mainResponse}
                data={standardTermResponse}
                isPrefilledCondition={isPrefilledCondition}
              />
            )}
            {isIssueRecapBCP && (
              <IssueRecapBaseCharterParty
                indicationId={indicationRequestId}
                reRenderApiFunc={reRenderApiFunc}
                data={BCPResponse}
                extendedContractIDBCP={extendedContractIDBCP}
                isPrefilledCondition={isPrefilledCondition}
              />
            )}
            {isIssueRecapRiderClauseTab && (
              <IssueRecapRiderClause
                indicationId={indicationRequestId}
                reRenderApiFunc={reRenderApiFunc}
                data={riderClauseResponse}
                isPrefilledCondition={isPrefilledCondition}
                extendedContractIDBCP={extendedContractIDBCP}
              />
            )}
          </div>
          {isLoader && (
            <div className="loader-div">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <WarningModalPopup
            onModalOpen={showWarningModal}
            onModalClose={() => setShowWarningModal(false)}
            modalTxt={currentStateType}
          />
          <ConfirmationModal
            onModalOpen={modalOpen}
            onModalClose={saveOrCancelIssueRecap}
            modalTxt={AppString.confirmIssueRecapModalTxt}
            width={"auto"}
            height={"140px"}
          />

          <ConfirmationModal
            onModalOpen={modalAckOpen}
            onModalClose={saveOrCancelAcknowledgementByVO}
            modalTxt={AppString.confirmAcknldgeVOModalTxt}
            width={"auto"}
            height={"140px"}
          />
          <ConfirmationModal
            onModalOpen={modalOpenCleanRecap}
            onModalClose={saveOrCancelCleanRecap}
            modalTxt={AppString.confirmCleanRecapModalTxt}
            width={"auto"}
            height={"140px"}
          />

          <ConfirmationModal
            onModalOpen={confirmationLiftSubsModal}
            onModalClose={saveOrCancelLiftSubs}
            modalTxt={AppString.confirmLiftSubsModalTxt}
            width={"auto"}
            height={"140px"}
          />

          <ConfirmationModal
            onModalOpen={voLiftSubConfirmationModal}
            onModalClose={saveOrCancelVoLiftSub}
            modalTxt={AppString.confirmLiftSubsModalTxt}
            width={"auto"}
            height={"140px"}
          />

          <ConfirmationModal
            onModalOpen={modalAckCleanRecapOpen}
            onModalClose={saveOrCancelAckCleanRecapByVO}
            modalTxt={AppString.confirmAcknCleanRecapByVOModalTxt}
            width={"auto"}
            height={"140px"}
          />
          <OfferTimeModal
            isModalOpen={liftSubsTimeModal}
            onCloseFunc={closeTimerModal}
            renderFunc={reRenderApiFunc}
            indicationId={indicationRequestId}
            checkWriteAccess={checkWriteAccess}
            negotiationType={[
              "Lift Subs",
              "Submit the Lift Subs TimeLine",
              "Submit Lift Subs",
            ]}
          />
          <IssueRecapSelectionModal
            isModalOpen={showPopupModal}
            onCloseFunc={() => setShowPopupModal(false)}
            onSubmitFunc={submitInitialIssueRecap}
          />
        </div>
      </div>
    </>
  );
};

export default IssueRecapHomePage;