import { useEffect, useState } from "react";
import { getAPI } from "../../../services/CepApiServices";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import moment from "moment";
import "./HistoryPage.scss";

interface IPROPS {
  isModalOpen: any;
  paragraphId: any;
  indicationRequestId: any;
  onClose(): any;
  isPrefilledCondition:any;
  extendedContractIDBCP:any;
}
const HistoryPage = ({
  isModalOpen,
  paragraphId,
  indicationRequestId,
  onClose,
  isPrefilledCondition,
  extendedContractIDBCP,
}: IPROPS) => {
  const [historyData, setHistoryData] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(true);
  const handleHistoryClick = async () => {
    let response:any;
    if(!isPrefilledCondition){
      response = await getAPI(
        `Negotiation/GetMainClauseHistory/${indicationRequestId}/${paragraphId}/false`
      );
    } else {
       response = await getAPI(
        `Negotiation/GetMainClauseHistory/${extendedContractIDBCP}/${paragraphId}/true`
      );
    }
  
    if (!response.StatusCode) {
      setHistoryData(response);
      setIsLoader(false);
    }else{
      onCancel();
    }
  };
  useEffect(() => {
    handleHistoryClick();
  }, []);

  const onCancel = () => {
    onClose();
  };

  const displayContent = (val: any) => {
    const lineNumber: any = String(val?.lineNumber);
    return (
      <>
        <div className="dataValue">
          <b>{lineNumber?.indexOf(".") !== -1 ? "" : val?.lineNumber}. </b>
          {val?.changeList?.length == 0 || val?.changeList === null
            ? val?.originalContent
            : returnJoinData(val?.changeList)}
        </div>
      </>
    );
  };

  const returnJoinData = (val: any) => {
    const joineddata: any = [];
    const highlightClasses = ['highlightBlack', 'highlightRed', 'highlightGreen'];
    val?.forEach((element: any) => {
      if (element?.displayFormat === 0) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</b>);
        } else {
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</b>);
        }
      }
      if (element?.displayFormat === 1) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{newArr}</s>);
        } else {
          joineddata.push(<s className={`${element?.highlight === 1 ? 'heighlightText': ''}`}>{element?.text}</s>);
        }
      }
      if (element?.displayFormat === 2) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(newArr);
        } else {
          joineddata.push(element?.text);
        }
      }
      if (element?.displayFormat === 3) {
        if (element?.text?.indexOf("<br>") > -1) {
          let newArr: any = [];
          newArr.push(element?.text?.replace(/<br\s*\\?>/g, "\r\n"));
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}><s>{newArr}</s></b>);
        } else {
          joineddata.push(<b className={`${element?.highlight === 1 ? 'heighlightText': ''}`}><s>{element?.text}</s></b>);
        }
      }
    });
    return joineddata;
  };

  return (
    <div className="history">
      <div className="closeIconDiv">
        <McIcon
          icon="times-circle"
          data-testid="close-icon"
          className="close-icon"
          onClick={() => onCancel()}
        ></McIcon>
      </div>
      <div className="history-page">
        {isLoader ? (
          <div className="loader-div" data-testid="loader">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <>
            {historyData?.length === 0 && (
              <div className="no-data">No Data Available</div>
            )}
             {historyData?.filter(item => item.createdUserType !== "System" && item.createdBy !== "System").map((item: any, index: any) => ( 
              <div key={index} className="key">
                <div className="row">
                  <div className="col history-head">
                    {(item?.createdUserType === "VesselOwner" || item?.createdUserType === "OwnerRepresentative") && (
                      <span className="voDiv">
                        <span className="user-icon">
                          <McIcon
                            icon="user"
                            color="#ff8766"
                            size="20"
                          ></McIcon>
                        </span>
                        <span className="text">Vessel Owner</span>
                      </span>
                    )}
                    {item?.createdUserType === "CharteringManager" && (
                      <div className="cmDiv">
                        <span className="user-icon">
                          <McIcon icon="user" color="#43afd5" size="20"></McIcon>
                        </span>
                        <span className="text">Chartering Manager</span>
                      </div>
                    )}
                  </div>
                  <span className="history-head-right col">
                    {" "}
                    {moment(item?.createdDate)
                      .format("DD-MMM-YYYY HH:mm:ss")}
                  </span>
                </div>
                <span className="createdBy">
                  Changes made by : {item?.createdBy}
                </span>
                <div className="edit-page">
                  {item?.mainClauseHistories?.filter((lineItem: any) => lineItem !== null).map((lineItem: any) => (
                    <div key={lineItem?.lineNumber}>   
                      {displayContent(lineItem)}
                    </div>
                  ))}
                </div>
                <div className="horizontal-line"></div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default HistoryPage;
