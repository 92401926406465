// React
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DateSlider from "../../../components/dateSlider/dateSlider";
import Select from "react-select";
import RangeSlider from "../../../components/rangeSlider/rangeSlider";
import "../../../components/rangeSlider/rangeSlider.scss";
import DropdownTreeSelect from "react-dropdown-tree-select";
import WarningModal from "./WarningModal";
import "react-dropdown-tree-select/dist/styles.css";
//MDS
import "@maersk-global/mds-components-core";
import {
  McCAccordionItem,
  McButton,
  McIcon,
} from "@maersk-global/mds-react-wrapper";
import { toast } from "react-toastify";

//Service
import { getAPI, postAPI, deleteAPI } from "../../../services/CepApiServices";
import Multiselect from "multiselect-react-dropdown";
import { fetchYearMonthDateFormat } from "../../../utils/customDateTime";

//CSS
import "./DetailsFilter.scss";
import moment from "moment";

interface IPROPS {
  closeFilterFunc?: any;
  filterDataCalled?: any;
  crId?: any;
}
function DetailsFilter({ closeFilterFunc, filterDataCalled, crId }: IPROPS) {
  const [filters, setFilters] = useState<any>([]);

  const [designData, setIsDesignData] = useState([]);
  const [designPlaceholder, setDesignPlaceholder] = useState<any>();
  const [designSearchText, setDesignSearchText] = useState<any>("");
  const [flagData, setFlagData] = useState([]);
  const [managingOwnerData, setManagingOwnerData] = useState([]);
  const [managingOwnerPlaceholder, setManagingOwnerPlaceholder] =
    useState<any>();
  const [managingOwnersearchText, setManagingOwnersearchText] =
    useState<any>("");
  const [operatorsData, setOperatorData] = useState([]);
  const [redelivPortsData, setRedeliveryPortsData] = useState([]);
  const [reDeliveryRangeData, setReDeliveryRangeData] = useState([] as any[]);
  const [rateTypesData, setRateTypesData] = useState([]);
  const [displayRateInput, setDisplayRateInput] = useState(false);
  const [newArr, setnewArr] = useState<any>([]);
  const [imoVal, setImoVal] = useState("");
  const [isWarningModal, setIsWarningModal] = useState(false);
  // let newArr: string[] = [];
  const [selectedRange, setselectedRange] = useState([] as any[]);
  const [selectedRange1, setselectedRange1] = useState([] as any[]);
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [teuFrom, setTeuFrom] = useState<any>(0);
  const [teuTo, setTeuTo] = useState<any>(0);
  const [reeferFrom, setReeferFrom] = useState<any>(0);
  const [reeferTo, setReeferTo] = useState<any>(0);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      open: "",
      designs: "",
      managingOwners: "",
      operators: "",
      flags: "",
      teuFrom: "",
      teuTo: "",
      loa: "",
      beam: "",
      draft: "",
      reeferFrom: "",
      reeferTo: "",
      gear: "",
      iceclass: "",
      rateTypes: "",
      rateTypeId: "",
      redeliveryPorts: "",
      redeliveryRanges: "",
      manualAmount: "",
    },
  });
  let formData = {};
  const clearFilter = () => {
    setIsWarningModal(true);
    // reset();
    // closeFilter();
    // // setdesign("");
    // setImoVal("");
    // closeFilterFunc("close");
  };

  const gearItems = [
    { value: true, label: "Yes" },
    { value: false, label: "NA" },
  ];
  const iceClassItems = [
    { value: true, label: "Yes" },
    { value: false, label: "NA" },
  ];
  const getGearValue = (obj) => {
    if (obj === true) return "Yes";
    else if (obj === false) return "NA";
  };

  const onSubmit = (data: any) => {
    const openDateDetails: any = getValues("open");
    formData = {
      id: 0,
      charterRequestId: Number(crId),
      openFrom: data.open
        ? moment(openDateDetails.startDateLabel).format("YYYY-MM-DD")
        : moment(filterDataCalled.openFrom).format("YYYY-MM-DD"),
      openTo: data.open
        ? moment(openDateDetails.endDateLabel).format("YYYY-MM-DD")
        : moment(filterDataCalled.openTo).format("YYYY-MM-DD"),
      teuFrom: data.teuFrom ? Number(data.teuFrom) : filterDataCalled?.teuFrom,
      teuTo: data.teuTo ? Number(data.teuTo) : filterDataCalled?.teuTo,
      loaFrom: data.loa ? data.loa[0] : filterDataCalled?.loaFrom,
      loaTo: data.loa ? data.loa[1] : filterDataCalled?.loaTo,
      beamFrom: data.beam ? data.beam[0] : filterDataCalled?.beamFrom,
      beamTo: data.beam ? data.beam[1] : filterDataCalled?.beamTo,
      draftFrom: data.draft ? data.draft[0] : filterDataCalled?.draftFrom,
      draftTo: data.draft ? data.draft[1] : filterDataCalled?.draftTo,
      reeferPlugsFrom: data.reeferFrom
        ? Number(data.reeferFrom)
        : filterDataCalled?.reeferPlugsFrom,
      reeferPlugsTo: data.reeferTo
        ? Number(data.reeferTo)
        : filterDataCalled?.reeferPlugsTo,
      gear: checkGear(data.gear),
      iceClass:
        data.iceclass != "" ? data.iceclass : filterDataCalled?.iceClass,
      // manualAmount: data.manualAmount !== "" ? Number(data.manualAmount) : 0,
      // Fix to prevent refresh of manualAmount on 2nd time save
      manualAmount:
        data.manualAmount !== ""
          ? Number(data.manualAmount)
          : filterDataCalled.manualAmount
          ? getManualAmountValue(
              filterDataCalled.manualAmount,
              data.rateTypes,
              filterDataCalled.rateTypeId
            )
          : 0,
      // rateTypeId: data.rateTypes
      //   ? data.rateTypes
      //   : filterDataCalled?.rateTypeId
      //   ? filterDataCalled?.rateTypeId
      //   : null,
      // Fix to add None in dropdown
      rateTypeId:
        (data.rateTypes == 0 || data.rateTypes > 0) && data.rateTypes !== ""
          ? getRateTypValue(data.rateTypes)
          : filterDataCalled.rateTypeId
          ? filterDataCalled.rateTypeId
          : null,
      designs:
        data.designs === ""
          ? getVal(
              filterDataCalled.designs?.filter((e: any) => e.checked == true)
            )
          : data.designs.length == 0
          ? []
          : getVal(data.designs),

      managingOwners:
        data.managingOwners === ""
          ? getVal(
              filterDataCalled.managingOwners?.filter(
                (e: any) => e.checked == true
              )
            )
          : data.managingOwners.length == 0
          ? []
          : getVal(data.managingOwners),

      operators:
        data.operators === ""
          ? getVal(
              filterDataCalled.operators?.filter((e: any) => e.checked == true)
            )
          : data.operators.length == 0
          ? []
          : getVal(data.operators),

      flags:
        data.flags === ""
          ? getLabel(
              filterDataCalled.flags?.filter((e: any) => e.checked == true)
            )
          : data.flags.length == 0
          ? []
          : getLabel(data.flags),

      redeliveryPorts:
        data.redeliveryPorts === ""
          ? getVal(
              filterDataCalled.redeliveryPorts?.filter(
                (e: any) => e.checked == true
              )
            )
          : data.redeliveryPorts.length == 0
          ? []
          : getVal(data.redeliveryPorts),

      redeliveryRanges: isPageLoad
        ? newArr
        : getSelectedRedeliveryRanges(reDeliveryRangeData),
    };
    // console.log(formData); //* use it wen ur doing testing and comment the next line
    saveCharterRequestDetailsFilters(formData);
  };
  const getRateTypValue = (val: any) => {
    if (val == 0) {
      return null;
    } else {
      return val;
    }
  };
  const getManualAmountValue = (
    previousManualAmt: any,
    dataValRateType: any,
    previousRateType: any
  ) => {
    if (previousManualAmt) {
      if (dataValRateType !== "") {
        if (dataValRateType !== 0) {
          return previousManualAmt;
        }
        if (dataValRateType == 0) {
          return 0;
        }
      }
      if (previousRateType !== "") {
        if (previousRateType !== 0) {
          return previousManualAmt;
        }
        if (previousRateType == 0) {
          return 0;
        }
      }
    }
  };
  const checkGear = (val: any) => {
    if (val === "") {
      return filterDataCalled?.gear;
    } else {
      return val;
    }
  };
  const getVal = (val) => {
    if (Array.isArray(val)) {
      return val.map((h) => h.value);
    } else {
      return [];
    }
  };

  const getLabel = (val) => {
    if (Array.isArray(val)) {
      return val.map((h) => h.label);
    } else {
      return [];
    }
  };

  const saveCharterRequestDetailsFilters = async (data) => {
    const savedFiltersData = await postAPI(
      `CharterRequest/SaveCharterRequestDetailFilter`,
      data
    );
    if (savedFiltersData?.StatusCode) {
      closeFilterFunc("close");
      toast.error(`Saving of filters failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      closeFilterFunc("save");
      toast.success(`Filters saved successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  const closewarningFilter = async (closeItem: any) => {
    // console.log(closeItem)
    if (closeItem === "Submit") {
      const response = await deleteAPI(
        `CharterRequest/DeleteCharterRequestAppliedFilter?requestId=${crId}`
      );
      if (response === true) {
        toast.success(`Filters reset successfully`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        closeFilterFunc("save");
        reset();
        closeFilter();
        setImoVal("");
      }
    }
    setIsWarningModal(false);
  };
  useEffect(() => {
    getSelectedRateType(filterDataCalled?.rateTypes);
    fetchFiltersForCRDetails(crId);
  }, []);

  const fetchFiltersForCRDetails = async (crId) => {
    const filtersData = await getAPI(
      `CharterRequest/GetCharterRequestDetailFilter/` + crId
    );

    setFilters(filtersData);
    setIsDesignData(filtersData?.designs);
    setDesignPlaceholder(
      `${
        filtersData?.designs?.filter((item: any) => item.checked).length
      } selected out of ${filtersData?.designs?.length}`
    );
    setFlagData(filtersData?.flags);
    setManagingOwnerData(filtersData?.managingOwners);
    setManagingOwnerPlaceholder(
      `${
        filtersData?.managingOwners?.filter((item: any) => item.checked).length
      } selected out of ${filtersData?.managingOwners?.length}`
    );
    setOperatorData(filtersData?.operators);
    let newrateType: any = filtersData?.rateTypes;
    newrateType?.unshift({ value: 0, label: " None " });
    setRateTypesData(newrateType);
    setRedeliveryPortsData(filtersData?.redeliveryPorts);
    // setReDeliveryRangeData(filtersData?.redeliveryRanges);
    setTeuFrom(filtersData?.teuFrom);
    setTeuTo(filtersData?.teuTo);
    setReeferFrom(filtersData?.reeferPlugsFrom);
    setReeferTo(filtersData?.reeferPlugsTo);
    if (filtersData?.redeliveryRanges) {
      const initialRedeliveryRangeState = filtersData.redeliveryRanges.map(
        (range) => {
          const allChildrenChecked = range.children.every(
            (child) => child.checked
          );
          return {
            ...range,
            checked: allChildrenChecked,
          };
        }
      );

      setReDeliveryRangeData(initialRedeliveryRangeState);
    }
  };
  const closeFilter = () => {
    closeFilterFunc("close");
  };

  const setSelectedFiltersValue = (val: any) => {
    let objIndex = val.findIndex((obj: any) => obj?.checked == false);
    if (objIndex !== -1) {
      val[objIndex].checked = true;
    }
  };

  const rateTypeChanged = (val) => {
    if (val == "Manual Rate") {
      setDisplayRateInput(true);
    } else {
      setDisplayRateInput(false);
    }
  };
  const getPortsByRange = async (arr) => {
    const portsData = await postAPI(
      `CharterRequest/GetRedeliveryPortsBasedOnRangeCountryAsync/`,
      arr
    );
    setValue("redeliveryPorts", portsData);
    setRedeliveryPortsData(portsData);
  };
  const onChangeRange = (currentNode, selectedNodes) => {
    let arr: string[] = [];
    selectedNodes?.forEach((element: any) => {
      if (element?._depth == 1) {
        arr.push(element.id);
      } else {
        const selectedRange = reDeliveryRangeData?.filter(
          (e: any) => e.label == element.label
        );
        selectedRange[0]?.children.forEach((e) => {
          arr.push(e.id);
        });
      }
    });
    setnewArr(arr);

    const updatedRedeliveryRange = reDeliveryRangeData.map((range: any) => {
      const allChildrenChecked = range.children.every((item) =>
        arr.includes(item.id)
      );
      return {
        ...range,
        checked: allChildrenChecked || arr.includes(range.id),
        children: range.children.map((item) => ({
          ...item,
          checked: arr.includes(item.id),
        })),
      };
    });
    setReDeliveryRangeData(updatedRedeliveryRange);
    setIsPageLoad(true);
    getPortsByRange(arr);
  };

  const getSelectedRedeliveryRanges = (val: any) => {
    if (val == null) {
      setnewArr([]);
      return newArr;
    }
    let arr1: any = [];
    val?.forEach((l: any) => {
      l?.children?.forEach((n: any) => {
        if (n.checked == true) {
          arr1.push(n.id);
        }
      });
    });
    setnewArr(arr1);
    return arr1;
  };
  const getSelectedRateType = (filterArr: any) => {
    let arr: any[] = [];
    arr = filterArr?.filter(
      (e: any) => e.value == filterDataCalled?.rateTypeId
    );
    rateTypeChanged(arr ? arr[0]?.label : "");
    return arr;
  };

  return (
    <form
      data-testid="details-filter"
      className="charterMarketDetailsFilter"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="filterModal">
        <div className="filter-section">
          <div className="input-mainSection">
            {filters?.length === 0 && (
              <div className="loader-div">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            <div className="header-section">
              <h5 className="header-text">Apply Filter</h5>
              <div
                className="closeIcon"
                data-testid="close"
                onClick={closeFilter}
              >
                <McIcon icon="times"></McIcon>
              </div>
            </div>
            <div className="filters-subsection longer-section">
              <McCAccordionItem title="" id="1" expanded={true} fit="medium">
                <label>Open</label>
                <Controller
                  name="open"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange } }) => (
                    <DateSlider
                      startDate={filterDataCalled?.openFrom}
                      endDate={filterDataCalled?.openTo}
                      onRRChange={(e) => onChange(e)}
                    />
                  )}
                />

                <div className="input-section design-section">
                  <label>Design</label>
                  {designSearchText === "" && (
                    <label className="design-placeholder">
                      {designPlaceholder}
                    </label>
                  )}
                  <Controller
                    name="designs"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        id="design-multi-select"
                        options={designData}
                        placeholder=""
                        selectedValues={designData?.filter(
                          (e: any) => e.checked == true
                        )}
                        onSelect={(e) => {
                          setDesignSearchText("");
                          setDesignPlaceholder(
                            `${e.length} selected out of ${designData?.length}`
                          );
                          onChange(e);
                          setSelectedFiltersValue(e);
                        }}
                        onRemove={(e) => {
                          setDesignSearchText("");
                          setDesignPlaceholder(
                            `${e.length} selected out of ${designData?.length}`
                          );
                          onChange(e);
                          setSelectedFiltersValue(e);
                        }}
                        displayValue="label"
                        showCheckbox
                        onSearch={(text) => setDesignSearchText(text)}
                      />
                    )}
                  />
                </div>
                <div className="input-section managing-owner-section">
                  <label>Managing Owner</label>
                  {managingOwnersearchText === "" && (
                    <label className="managing-owner-placeholder">
                      {managingOwnerPlaceholder}
                    </label>
                  )}
                  <Controller
                    name="managingOwners"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        id="managing-owner-multi-select"
                        placeholder=""
                        options={managingOwnerData}
                        selectedValues={managingOwnerData?.filter(
                          (e: any) => e.checked == true
                        )}
                        onSelect={(e) => {
                          setManagingOwnersearchText("");
                          setManagingOwnerPlaceholder(
                            `${e.length} selected out of ${managingOwnerData?.length}`
                          );
                          onChange(e);
                          setSelectedFiltersValue(e);
                        }}
                        onRemove={(e) => {
                          setManagingOwnersearchText("");
                          setManagingOwnerPlaceholder(
                            `${e.length} selected out of ${managingOwnerData?.length}`
                          );
                          onChange(e);
                          setSelectedFiltersValue(e);
                        }}
                        displayValue="label"
                        showCheckbox
                        onSearch={(text) => setManagingOwnersearchText(text)}
                      />
                    )}
                  />
                </div>
                <div className="input-section">
                  <label>Operator</label>
                  <Controller
                    name="operators"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        placeholder="Operator"
                        options={operatorsData}
                        selectedValues={operatorsData?.filter(
                          (e: any) => e.checked == true
                        )}
                        onSelect={(e) => {
                          onChange(e);
                          // setSelectedFiltersValue(e);
                        }}
                        onRemove={(e) => {
                          onChange(e);
                          // setSelectedFiltersValue(e);
                        }}
                        displayValue="label"
                        showCheckbox
                      />
                    )}
                  />
                </div>
                <div className="flagDiv input-section">
                  <label>Flag</label>
                  <Controller
                    name="flags"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <Multiselect
                        placeholder="Flag"
                        options={flagData}
                        selectedValues={flagData?.filter(
                          (e: any) => e.checked == true
                        )}
                        onSelect={(e) => {
                          onChange(e);
                          // setSelectedFiltersValue(e);
                        }}
                        onRemove={(e) => {
                          onChange(e);
                          // setSelectedFiltersValue(e);
                        }}
                        displayValue="label"
                        showCheckbox
                      />
                    )}
                  />
                </div>
                <div className="input-section">
                  <label>TEU</label>
                  <div className="teu-items">
                    <div className="TeuFrom">
                      <label>From</label>
                      <input
                        className="teu-input"
                        type="number"
                        value={teuFrom}
                        {...register("teuFrom")}
                        onChange={(e: any) => {
                          setTeuFrom(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="TeuTo">
                      <label>To</label>
                      <input
                        className="teu-input"
                        type="number"
                        value={teuTo}
                        {...register("teuTo")}
                        onChange={(val: any) => {
                          setTeuTo(val.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="input-section">
                  <label>LOA</label>
                  <Controller
                    name="loa"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <RangeSlider
                        name="loa"
                        min={filterDataCalled?.loaFrom}
                        max={filterDataCalled?.loaTo}
                        intialMin={filterDataCalled?.loaMin}
                        intialMax={filterDataCalled?.loaMax}
                        onRRChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className="input-section">
                  <label>Beam</label>
                  <Controller
                    name="beam"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <RangeSlider
                        name="beam"
                        min={filterDataCalled?.beamFrom}
                        max={filterDataCalled?.beamTo}
                        intialMin={filterDataCalled?.beamMin}
                        intialMax={filterDataCalled?.beamMax}
                        onRRChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className="input-section">
                  <label>Draft</label>
                  <Controller
                    name="draft"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <RangeSlider
                        name="draft"
                        min={filterDataCalled?.draftFrom}
                        max={filterDataCalled?.draftTo}
                        intialMin={filterDataCalled?.draftMin}
                        intialMax={filterDataCalled?.draftMax}
                        onRRChange={onChange}
                        stepName={"decimal"}
                      />
                    )}
                  />
                </div>

                <div className="input-section">
                  <label>Reefer Plugs</label>
                  <div className="reefer-items">
                    <div className="ReeferFrom">
                      <label>From</label>
                      <input
                        className="reefer-input"
                        type="number"
                        value={reeferFrom}
                        {...register("reeferFrom")}
                        onChange={(e: any) => {
                          setReeferFrom(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="ReeferTo">
                      <label>To</label>
                      <input
                        className="reefer-input"
                        type="number"
                        value={reeferTo}
                        {...register("reeferTo")}
                        onChange={(val: any) => {
                          setReeferTo(val.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="input-section" data-testid="gear">
                  <label>Gear</label>
                  <Controller
                    control={control}
                    name="gear"
                    render={({ field: { onChange } }) => (
                      <Select
                        defaultValue={{
                          value: filterDataCalled?.gear,
                          label: getGearValue(filterDataCalled?.gear),
                        }}
                        options={gearItems}
                        name="gear"
                        onChange={(val: any) => onChange(val.value)}
                      />
                    )}
                  />
                </div>

                <div className="input-section" data-testid="iceclass">
                  <label>Ice Class</label>
                  <Controller
                    control={control}
                    name="iceclass"
                    render={({ field: { onChange } }) => (
                      <Select
                        defaultValue={{
                          value: filterDataCalled?.iceClass,
                          label: getGearValue(filterDataCalled?.iceClass),
                        }}
                        options={iceClassItems}
                        name="iceclass"
                        onChange={(val: any) => onChange(val.value)}
                      />
                    )}
                  />
                </div>

                <div className="input-section">
                  <label>Redelivery Ranges</label>
                  <Controller
                    name="redeliveryRanges"
                    data-testid="redelivery-ranges"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <DropdownTreeSelect
                        className="custom-treeselect"
                        data={reDeliveryRangeData}
                        onChange={(currentNode, selectedNodes) =>
                          onChangeRange(currentNode, selectedNodes)
                        }
                      />
                    )}
                  />
                </div>
                <div
                  className="input-section ports-section"
                  style={{ paddingBottom: "40px" }}
                >
                  <label> Redelivery Port</label>
                  <div className="redeliveryPortsDiv">
                    <Controller
                      name="redeliveryPorts"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: filterDataCalled?.redeliveryPorts == null,
                      }}
                      render={({ field: { onChange } }) => (
                        <Multiselect
                          placeholder="Redelivery Port"
                          // name="redeliveryPort"
                          options={redelivPortsData}
                          selectedValues={redelivPortsData?.filter(
                            (e: any) => e.checked == true
                          )}
                          onSelect={(e) => {
                            onChange(e);
                          }}
                          onRemove={(e) => {
                            onChange(e);
                          }}
                          displayValue="label"
                          showCheckbox
                        />
                      )}
                    />
                    {errors.redeliveryPorts && (
                      <span className="error-msg">This field is required</span>
                    )}
                  </div>
                </div>
              </McCAccordionItem>
            </div>
            <div className="filters-subsection">
              <McCAccordionItem
                title="Rate"
                id="1"
                expanded={true}
                fit="medium"
              >
                <div className="input-section" data-testid="rateTypes">
                  <label>Rate Types</label>
                  <Controller
                    control={control}
                    name="rateTypes"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={rateTypesData}
                        name="rateTypes"
                        defaultValue={
                          filterDataCalled?.rateTypeId
                            ? {
                                label: filterDataCalled?.rateTypes?.filter(
                                  (e: any) =>
                                    e.value == filterDataCalled?.rateTypeId
                                )?.[0]?.label,
                              }
                            : {
                                label: "Select Rate Type",
                                value: null,
                              }
                        }
                        onChange={(val: any) => {
                          onChange(val.value);
                          rateTypeChanged(val.label);
                        }}
                      />
                    )}
                  />
                </div>

                {displayRateInput && (
                  <div className="col">
                    <div className="data-div">
                      <label>Manual Amount</label>
                      <Controller
                        name="manualAmount"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <input
                            data-testid="manualAmount"
                            defaultValue={filterDataCalled?.manualAmount}
                            className="inputText"
                            type="number"
                            onChange={(val) => {
                              onChange(val.target.value);
                            }}
                          ></input>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div className="spacing-div"></div>
              </McCAccordionItem>
            </div>
          </div>
          <WarningModal
            isModalOpen={isWarningModal}
            indicationId={0}
            onCanceled={closewarningFilter}
          />
          <div className="footer-fixed">
            <div className="button-apply">
              <div className="input-section">
                <McButton
                  data-testid="apply-filter"
                  type="submit"
                  label="Apply filter"
                  width="full-width"
                  fit="small"
                ></McButton>
              </div>
            </div>
            <div className="button-apply">
              <div className="input-section">
                <button
                  data-testid="clear"
                  className="reset-btn"
                  type="button"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Reset filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default DetailsFilter;
