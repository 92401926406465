import React, { useEffect, useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";
import {
  deleteAPI,
  deleteAPIForDocument,
  downloadReportDocuments,
  getAPI,
  postAPI,
} from "../../services/CepApiServices";

import "./DocumentLibraryTable.scss";
import { SkeletonLoader } from "../../components/skeletonLoader/SkeletonLoader";
import { DocumentLibraryModel } from "./DocumentLibraryModel";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { IconPdfDL } from "../../components/Icons/pdfIcon";
import moment from "moment";
import ConfirmationModal from "../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../utils/AppString";
import { toast } from "react-toastify";
interface IPROPS {
  tableData: any;
  isTableLoader?: any;
  isDocumentListChanges?: any;
  rerenderFunc?: any;
}
const DocumentLibraryTable = ({
  tableData,
  isTableLoader,
  isDocumentListChanges,
  rerenderFunc,
}: IPROPS) => {
  const [modalValue, setModalValue] = useState(false);
  const [rowValue, setRowValue] = useState<DocumentLibraryModel>();

  const downloadReportDL = async (
    fileName: string,
    docCategoryType: string
  ) => {
    await downloadReportDocuments(
      `DocumentLibrary/DownlaodDocument/${fileName}/${docCategoryType}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Data is not loading");
      });
  };
  const deleteFun = (row: any) => {
    setRowValue(row);
    setModalValue(true);
  };
  const deleteReport = async (response: any) => {
    if (response == "Yes") {
      const deletedDocumentDetails = {
        DocumentId: rowValue?.documentId,
        DocumentName: rowValue?.documentName,
        DocumentCategoryId: rowValue?.documentCategoryId,
        Checked: true,
        Status: rowValue?.status,
        DocumentCategoryName: rowValue?.documentCategoryName,
        FormFile: rowValue?.formFile,
        StatusId: rowValue?.statusId,
        CreatedBy: null,
        CreatedOn: rowValue?.createdOn,
      };

      const res = await postAPI(
        `DocumentLibrary/DeleteDocument`,
        deletedDocumentDetails
      );
      setModalValue(false);
      // isDocumentListChanges("Yes");
      if (res === true) {
        notify("Document Deleted Successfully", true);
        //below line added to refresh the table after deletion
        rerenderFunc();
      } else {
        notify("Document is referenced and deletion failed", false);
      }
    } else {
      //Only close the modal
      setModalValue(false);
      isDocumentListChanges("No");
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const documentNameWithIcon = (docName: any) => {
    const ext = docName.substr(docName.lastIndexOf(".") + 1);
    return (
      <span>
        <span style={{ paddingRight: "8px" }}>
          <IconPdfDL nameOfDoc={ext} />
        </span>
        <span>{docName}</span>
      </span>
    );
  };

  const documentStatusList = (statusId: any, statusName: any) => {
    switch (statusId) {
      case 58:
        return (
          <span className="status-negotiation status-label">{statusName}</span>
        );
      case 60:
        return (
          <span className="status-success status-label">{statusName}</span>
        );
      case 61:
        return <span className="status-reject status-label">{statusName}</span>;
      case 62:
        return <span className="status-sent status-label">{statusName}</span>;
      default:
        return <span className="status-label">{statusName}</span>;
    }
  };
  const columns: TableColumn<DocumentLibraryModel>[] = [
    {
      name: "Document Name",
      cell: (row) => documentNameWithIcon(row.documentName),
      selector: (row) => row.documentName,
      sortable: true,
      width: "17%",
    },
    {
      name: "Status",
      selector: (row) => (row.status !== null ? row.status : ""),
      cell: (row) => documentStatusList(row.statusId, row.status),
      sortable: true,
      width: "9%",
    },
    {
      name: "Comments",
      cell: (row) =>
        row.retryCount !== null && row.retryCount > 3
          ? AppString.extractionFailureTxt
          : "",
      sortable: true,
      width: "14%",
    },
    {
      name: "Document Category",
      selector: (row) =>
        row.documentCategoryName !== null ? row.documentCategoryName : "",
      sortable: true,
      width: "12.5%",
    },
    {
      name: "Uploaded By",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "15%",
    },
    {
      name: "Uploaded On",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
      sortable: true,
      width: "10%",
    },
    {
      name: "Start Page",
      selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
      cell: (row) => (row.startPage !== null ? row.startPage : ""),
      sortable: true,
      width: "7.3%",
    },
    {
      name: "Download",
      width: "7%",
      cell: (row) => (
        <span className="download-dl">
          <McIcon
            icon="tray-arrow-down"
            data-testid="download-document-library-data"
            onClick={() =>
              downloadReportDL(row.documentName, row.documentCategoryName)
            }
          ></McIcon>
        </span>
      ),
    },
    {
      name: "Action",
      width: "8%",
      cell: (row) =>
        row.status === "Reviewed" ? (
          ""
        ) : (
          <span>
            <div
              data-testid="delete-document-library-data"
              className="delete-icon-library-table"
              onClick={() => deleteFun(row) }
            >
              <McIcon icon="trash" color="#f00"></McIcon>
            </div>
            {/* <McButton
              data-testid="delete-document-library-data"
              fit={"small"}
              color="#f00"
              onClick={() => deleteFun(row)}
            >
              Delete
            </McButton> */}
          </span>
        ),
    },
  ];

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  return (
    <div className="document-library-table-main">
      <div className="ir-table-main table-margin1 bg-white">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={isTableLoader}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />

        <ConfirmationModal
          onModalOpen={modalValue}
          onModalClose={deleteReport}
          modalTxt={AppString.deleteModalTxt}
          width={"400px"}
          height={"130px"}
        />
      </div>
    </div>
  );
};
export default DocumentLibraryTable;
