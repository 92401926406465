import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
    isModalOpen?: boolean;
    isCloseModal?: any;
    infoData?: any;
}

const BrokerDetailsModal = ({ isModalOpen, isCloseModal, infoData }: IPROPS) => {
    const commentValue = infoData?.comment;
    const closeFilter = () => {
        isCloseModal();
    }
    return (
        <McModal
            height="500px"
            width="840px"
            className="broker-info-modal"
            heading={`Representative Details`}
            open={isModalOpen}
            backdropcloseactiondisabled
        >
            <div
                className="closeIcon-modal"
                data-testid="close"
                onClick={closeFilter}
            >
                <McIcon icon="times"></McIcon>
            </div>
            <div className="broker-data">
                <div className="broker-data-io details-section">
                    {infoData?.cmrAssignmentUserDetails?.map((data: any) => {
                        return (
                            <div className="row details-row">
                                <div className="col-md-4">
                                    <div className="header space-btw">Name</div>
                                    <div className="value">{data.name}</div>
                                </div>
                                <div className="col-md-4">
                                    <div className="header">E-mail</div>
                                    <div className="value">{data.email}</div>
                                </div>
                                <div className="col-md-4">
                                    <div className="header">Access type</div>
                                    <div className="value">
                                        {data.accessType}
                                    </div>
                                </div>
                            </div>
                        )

                    })}
                </div>


                <div className="broker-data-io details-section">
                    <div className="col-md-11 comments-section">
                        <label className="comment-label">Comments</label>
                        <textarea
                            data-testid="cm-comments"
                            className="commentArea"
                            defaultValue={
                                commentValue !== null ? commentValue : ""
                            }
                            placeholder="comments"
                            rows={3}
                        ></textarea>
                    </div>
                </div>
            </div>
            <McButton
                slot="secondaryAction"
                variant="primary"
                dialogAction="cancel"
                onClick={isCloseModal}
                role="cancel-cp"
                fit="small"
            >
                Close
            </McButton>
        </McModal>
    )
}

export default BrokerDetailsModal