import { al } from "../auth/al-login-config";
import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";
import jwt_decode from "jwt-decode";
// import { protectedResources } from "./authConfig";

export const getToken = async (scopes: any) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: scopes,
  });

  return response.accessToken;
};

export const ifAzureFlow = () => protectedResources.apiTodoList.isAzure;

export const getUserRole = async () => {
  let accessToken: any;
  if (protectedResources.apiTodoList.isAzure) {
    accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
  } else {
    accessToken = al.storage.id_token
    //localStorage.getItem("frJwt");
  }
  const roles = [
    "CharteringManager",
    "VesselOwner",
    "OwnerRepresentative",
    "CEPDocumentReviewer",
    "Deploymentmanager",
    "Postfixtureteam",
    "charteringManagerRepresentative"
  ];
  let decoded: any = [];
  decoded = jwt_decode(accessToken);
  const userRole = decoded?.roles.find((role) => roles.includes(role));
  return userRole;
};

export const getUserName = async () => {
  let accessToken: any;
  if (protectedResources.apiTodoList.isAzure) {
    accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
  } else {
    accessToken = al.storage.id_token 
  }
  let decoded: any = [];
  decoded = jwt_decode(accessToken);
  return decoded;
}

export const getUID = () => {
  const account = msalInstance.getActiveAccount();
  const uid = account?.name;
  if (!account) {
    // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  return uid;
};

export const getUserMailID = () => {
  const account = msalInstance.getActiveAccount();
  const user_mailID = account?.username;
  if (!account) {
    // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  return user_mailID;
};

export const getExpiryTime = async () => {
  let accessToken: any;
  if (protectedResources.apiTodoList.isAzure) {
    accessToken = await getToken(protectedResources.apiTodoList.scopes.read);
  } else {
    accessToken = al.storage.id_token
  }
  let decoded: any = [];
  decoded = jwt_decode(accessToken);
  const expiryTime = decoded?.exp;
  const expDate = new Date(expiryTime * 1000); 
  const expDateString = expDate.toString();
  return expDateString;
}
