import React, { useState, useEffect } from "react";

import {
  getAPI,
  downloadReportDocuments,
  fileApiAxios,
  deleteAPI,
} from "../../../services/CepApiServices";
import "../../IndicationRequestPages/Details/IndicationRequestDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { McLoadingIndicator } from "@maersk-global/mds-react-wrapper";
import ConfirmationModal from "../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import UploadFiles from "../../IndicationRequestPages/Details/UploadFiles";
import { AppString } from "../../../utils/AppString";
import { IconPdfDL } from "../../../components/Icons/pdfIcon";
import laderImage from "../../../assets/loader-image.png";
import StepIndicatorFlow from "../../../components/StepIndicationFlow/StepIndicatorFlow";
const IndicationRequestVODetails = () => {
  const [indicationRequestDeatils, setIndicationRequestDeatils] = useState<any>(
    []
  );
  const [indicationDocumentsDetails, setIndicationDocumentsDetails] =
    useState<any>([]);
  const [indicationUploadedDocs, setIndicationUploadedDocs] = useState<any>([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [DocumetsData, setDocumentsData] = useState(false);
  const [uploadModalValue, setUploadModalValue] = useState(false);
  const [sourceTypeId, setSourceTypeId] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const location = useLocation();
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [isConfrmModal, setIsConfrmModal] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [fileNameForDelete, setFileNameForDelete] = useState();
  const [fileIdForDelete, setFileIdForDelete] = useState(0);
  const [pageLoadOnDelete, setPageaLoadOnDelete] = useState(false);
  const [statusId, setStatusId] = React.useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [checkVoRespond, setCheckVoRespond] = useState(false);
  const [checkVoNotRespond, setCheckVoNotRespond] = useState(false);
  const [indexVo, setIndexVO] = React.useState(1);
  const [indexCm, setIndexCm] = React.useState(0);
  const [showUploadAndDel, setShowUploadAndDel] = useState(false);
  const [availableDocumentList, setAvailableDocumentList] = useState<any>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>({});

  const fetchVesselListCRDetails = async (id: any) => {
    setIsTableLoader(true);
    const vessels = await getAPI(
      `IndicationRequest/GetIndicationInviteResponseForVO/` +
        id +
        `?usertype=${location.state?.usertype}`
    );

    if (vessels) {
      setIndicationRequestDeatils(vessels?.indicationRequestData);
      setIndicationDocumentsDetails(vessels?.indicationRequestDocuments);
      const filteredDocuments = vessels?.indicationRequestDocuments?.filter(
        (document) => document.uploadedFileName
      );
      setIndicationUploadedDocs(filteredDocuments);
      const StatusIdValue = vessels?.indicationRequestData?.[0]?.statusId;
      setStatusId(StatusIdValue);
      if(StatusIdValue == 6 || (StatusIdValue >= 8 && StatusIdValue <= 15)){
        setShowUploadAndDel(true);
      }else if (StatusIdValue < 6 || StatusIdValue == 7 || StatusIdValue == 16 || StatusIdValue >= 17){
        setShowUploadAndDel(false);
      }
      let voRespond = vessels?.indicationRequestData?.some(function (
        item: any,
        index: any
      ) {
        if (item["userTypeId"] != 52) {
          setIndexVO(index);
        }
        return item["userTypeId"] == 53 || item["userTypeId"] == 54;
      });

      let voNotRespond = vessels?.indicationRequestData?.some(function (
        item: any,
        index: any
      ) {
        if (item["userTypeId"] == 52) {
          setIndexCm(index);
        }
        return item["userTypeId"] == 52;
      });
      setCheckVoRespond(voRespond);
      setCheckVoNotRespond(voNotRespond);

      if (vessels?.indicationRequestDocuments?.length == 0) {
        setDocumentsData(true);
      } else {
        setDocumentsData(false);
      }
    }
    setIsTableLoader(false);
  };
  const deleteFilesHandler = async (id: any, fileName: any) => {
    setIsConfrmModal(true);
    setFileNameForDelete(fileName);
    setFileIdForDelete(id);
  };
  const handleDownload = async (id: any, fileName: string) => {
    let filesName = id + "_" + fileName;
    await downloadReportDocuments(
      `IndicationRequest/DownlaodIndicationRequestDocument/${filesName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("data is not coming");
      });
  };

  const onBtnClickOut = (id: any, fileName: any) => {
    return handleDownload(id, fileName);
  };
  const closingUpload = (act: any, data: any, typeValue: any) => {
    setUploadModalValue(false);
  };

  const deleteFun = async (response: string) => {
    if (response == "Yes") {
      let res = await deleteAPI(
        `IndicationRequest/DeleteIndicationRequestDocument/` +
          fileIdForDelete +
          `?fileName=${fileNameForDelete}`
      );
      setIsConfrmModal(false);
      setPageaLoadOnDelete(true);
    } else {
      //Only close the modal
      setIsConfrmModal(false);
    }
  };
  const saveUploadedDocuments = async () => {
    const indicationRequestDocObj = [
      {
        id: 0,
        documentId: selectedDocument?.documentId,
        formFile: fileUploaded,
        indicationRequestId: id,
        documentName: fileUploaded?.name,
        documentTypeId: sourceTypeId,
        createdBy: "",
        modifiedBy: "",
      },
    ];
    let formData: any = new FormData();
    let index = 0;
    for (let pair of indicationRequestDocObj) {
      formData.append(
        "indicationRequestDocuments[" + index + "].formFile",
        pair.formFile
      );
      formData.append("indicationRequestDocuments[" + index + "].id", pair.id);
      formData.append(
        "indicationRequestDocuments[" + index + "].documentId",
        pair.documentId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].indicationRequestId",
        pair.indicationRequestId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].documentName",
        pair.documentName
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].createdBy",
        pair.createdBy
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].modifiedBy",
        pair.modifiedBy
      );
    }
    if (fileUploaded) {
      await fileApiAxios(
        `IndicationRequest/UploadFiles?indicationId=${id}`,
        formData
      ).then((result) => {
        if (result.data == true) {
          setProgressStatus("success");
          setPageaLoadOnDelete(true);
          setTimeout(function () {
            setUploadModalValue(false);
          }, 1500);
        } else {
          setProgressStatus("danger");
          setTimeout(function () {}, 1000);
        }
      });
    }
  };
  const receiveUploadedFile = (file: File, source: any) => {
    setFileUploaded(file);
    setSelectedDocument(source);
  };
  const getAvailableOtherDocuments = async () => {
    const availableDocuments = indicationDocumentsDetails.filter(
      (doc) => !doc.uploadedFileName
    );
    setAvailableDocumentList(availableDocuments);
    console.log(availableDocuments);
  };
  useEffect(() => {
    getAvailableOtherDocuments();
  }, [indicationDocumentsDetails]);
  useEffect(() => {
    saveUploadedDocuments();
  }, [fileUploaded]);
  useEffect(() => {
    fetchVesselListCRDetails(id);
    setPageaLoadOnDelete(false);
  }, [pageLoadOnDelete, pageLoad]);

  const checkEqualData = (data1: any, data2: any) => {
    if (data1 != data2 && data2 != "null") {
      return <span className="status-warning">{data2}</span>;
    } else if (data2 == "null") {
      return "";
    } else {
      return <span>{data2}</span>;
    }
  };
  const handleUploadPopUp = () => {
    setUploadModalValue(true);
  };

  return (
    <>
      <div className="indication-details-page">
        <div className="loaderIndicator"></div>
        <div>
          <div className="mainDiv" style={{ marginBottom: "10px" }}>
            Indication Request Details
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="indication-flow">
                <StepIndicatorFlow
                  type={"Indication"}
                  indicationID={id}
                  updateApiProps={isTableLoader}
                />
              </div>
            </div>
          </div>
          {isTableLoader && (
            <div className="loader-div  ">
              <div className="width-div">
                <div className="image-div shipLoader" id="pot">
                  <img src={laderImage} alt="" />
                </div>
              </div>
            </div>
          )}

          {indicationRequestDeatils?.length == 2 && (
            <>
              <div className="indicationDetails-section">
                <div className="headingDiv-indication">
                  <div className="headingIcon">
                    <McIcon
                      icon={"file"}
                      color={"black"}
                      style={{ verticalAlign: "center" }}
                    ></McIcon>
                  </div>
                  <span className="textOfHeaderDiv">
                    {"Chartering Manager"}
                  </span>
                </div>

                <div className="main-container ">
                  <div className="vsl-details-overview">
                    <div className="row vsl-details-data">
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Delivery Port
                        </label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.deliveryPort}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Laycan From
                        </label>
                        <div className="vsl-details-value font-bold">
                        {indicationRequestDeatils[indexCm]?.laycanFrom == null
                          ? ""
                          : moment(
                            indicationRequestDeatils[indexCm]?.laycanFrom
                            ).format("DD-MMM-YYYY")}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Laycan To
                        </label>
                        <div className="vsl-details-value font-bold">
                        {indicationRequestDeatils[indexCm]?.laycanTo == null
                          ? ""
                          : moment(
                              indicationRequestDeatils[indexCm]?.laycanTo
                            ).format("DD-MMM-YYYY")}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">Period</label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.period}
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Time Charter Options
                        </label>
                        <div className="vsl-details-value font-bold">
                          {
                            indicationRequestDeatils[indexCm]
                              ?.timeCharterOptions
                          }
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">Hire</label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.hire}
                        </div>
                      </div>
                    </div>
                    <div className="row vsl-details-data">
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Re-Delivery Range
                        </label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.redeliveryRange}
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Address Commission
                        </label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.addressCommission}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Additional Broker Commission
                        </label>
                        <div className="vsl-details-value font-bold">
                          {
                            indicationRequestDeatils[indexCm]
                              ?.additionalBrokerCommission
                          }
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Charter Party
                        </label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.charterParty}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Owner's BOD Approval
                        </label>
                        <div className="vsl-details-value font-bold">
                          {indicationRequestDeatils[indexCm]?.ownersBodApproval
                            ? indicationRequestDeatils[0]?.ownersBodApproval
                            : ""}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Charter's BOD Approval
                        </label>
                        <div className="vsl-details-value font-bold">
                          {
                            indicationRequestDeatils[indexCm]
                              ?.charterBodApproval
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="indicationDetails-section">
                <div className="tab-row tab-section" slot="panel">
                  <div className="headingDiv-indication">
                    <div className="headingIcon">
                      <McIcon
                        icon={"file"}
                        color={"black"}
                        style={{ verticalAlign: "center" }}
                      ></McIcon>
                    </div>
                    <span className="textOfHeaderDiv">{"Vessel Owner"}</span>
                  </div>
                  <div className="main-container">
                    <div className="vsl-details-overview">
                      <div className="row vsl-details-data">
                        <div className="col">
                          <label className="cr-vsl-details-header">
                            Delivery Port
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]?.deliveryPort,
                              indicationRequestDeatils[indexVo]?.deliveryPort
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Laycan From
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              moment(
                                indicationRequestDeatils[indexCm]?.laycanFrom
                              ).format("DD-MMM-YYYY"),
                              moment(
                                indicationRequestDeatils[indexVo]?.laycanFrom
                              ).format("DD-MMM-YYYY")
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Laycan To
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              moment(
                                indicationRequestDeatils[indexCm]?.laycanTo
                              ).format("DD-MMM-YYYY"),
                              moment(
                                indicationRequestDeatils[indexVo]?.laycanTo
                              ).format("DD-MMM-YYYY")
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Period
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]?.period,
                              indicationRequestDeatils[indexVo]?.period
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="cr-vsl-details-header">
                            Time Charter Options
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.timeCharterOptions,
                              indicationRequestDeatils[indexVo]
                                ?.timeCharterOptions
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="cr-vsl-details-header">Hire</label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]?.hire,
                              indicationRequestDeatils[indexVo]?.hire
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row vsl-details-data">
                        <div className="col">
                          <label className="cr-vsl-details-header">
                            Re-Delivery Range
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.redeliveryRange,
                              indicationRequestDeatils[indexVo]?.redeliveryRange
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="cr-vsl-details-header">
                            Address Commission
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.addressCommission,
                              indicationRequestDeatils[indexVo]
                                ?.addressCommission
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Additional Broker Commission
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.additionalBrokerCommission,
                              indicationRequestDeatils[indexVo]
                                ?.additionalBrokerCommission
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Charter Party
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]?.charterParty,
                              indicationRequestDeatils[indexVo]?.charterParty
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Owner's BOD Approval
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.ownersBodApproval,
                              indicationRequestDeatils[indexVo]
                                ?.ownersBodApproval
                            )}
                          </div>
                        </div>
                        <div className="col ">
                          <label className="cr-vsl-details-header">
                            Charter's BOD Approval
                          </label>
                          <div className="vsl-details-value font-bold">
                            {checkEqualData(
                              indicationRequestDeatils[indexCm]
                                ?.charterBodApproval,
                              indicationRequestDeatils[indexVo]
                                ?.charterBodApproval
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="upload-section">
                    <div className="headingDiv-indication uploadHeading">
                      {showUploadAndDel && (
                        <div className="uploadButton">
                          <McButton
                            icon="tray-arrow-up"
                            onClick={handleUploadPopUp}
                            disabled={availableDocumentList?.length === 0}
                          >
                            Upload Document
                          </McButton>
                        </div>
                      )}
                      <span className="textOfHeaderDiv">
                        {"Uploaded Documents"}
                      </span>
                    </div>
                    {isTableLoader && (
                      <McLoadingIndicator label="Loading"></McLoadingIndicator>
                    )}
                    {/* <div className="NoFilesSection">
                      <span>No Files Found</span>
                    </div> */}
                    {isTableLoader && (
                      <McLoadingIndicator label="Loading"></McLoadingIndicator>
                    )}
                    {indicationUploadedDocs?.length === 0 && (
                      <div className="col-md-12">
                        <div className="row">
                          <div className="NoFilesSection">
                            <span>No Files Found</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {indicationUploadedDocs?.length > 0 && (
                      <div className="bodySection">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              {indicationUploadedDocs?.map((data: any) => (
                                <div className="col-md-3">
                                  <div className="attach-div">
                                    {showUploadAndDel && (
                                      <div className="delete-icon">
                                        <McIcon
                                          icon="trash"
                                          color="#c00"
                                          data-testid="delete-icon"
                                          onClick={() => {
                                            deleteFilesHandler(
                                              data?.id,
                                              data?.uploadedFileName
                                            );
                                          }}
                                        ></McIcon>
                                      </div>
                                    )}
                                    <div className="download-icon">
                                      <McIcon
                                        icon="tray-arrow-down"
                                        data-testid="download-icon"
                                        onClick={() => {
                                          onBtnClickOut(
                                            data?.id,
                                            data?.uploadedFileName
                                          );
                                        }}
                                      ></McIcon>
                                    </div>
                                    <p
                                      className="file-name"
                                      data-testid="file-name"
                                    >
                                      <IconPdfDL
                                        nameOfDoc={
                                          data?.documentName
                                            .split(".")
                                            .slice(-1)[0]
                                        }
                                      ></IconPdfDL>{" "}
                                      {data?.documentName
                                        .split(".")
                                        .slice(0, -1)
                                        .join(".")}
                                    </p>
                                    {/* <div className="user-type" data-testid="user-type">
                              User Type : {data?.userType}
                            </div> */}
                                    <div
                                      className="uploaded-by"
                                      data-testid="uploaded-by"
                                    >
                                      Uploaded By: {data?.uploadedBy}
                                    </div>
                                    {/* <div className="uploaded-file-name" data-testid="uploaded-file-name">
                              File Name: {data?.uploadedFileName}
                            </div> */}
                                  </div>
                                </div>
                              ))}

                              <ConfirmationModal
                                onModalOpen={isConfrmModal}
                                onModalClose={deleteFun}
                                modalTxt={AppString.deleteModalTxt}
                                width={"400px"}
                                height={"130px"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  <UploadFiles
                    onOpen={uploadModalValue}
                    closeUpload={closingUpload}
                    fileUpload={receiveUploadedFile}
                    availableDocumentList={availableDocumentList}
                    progressStatusRes={progressStatus}
                  />
                }
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default IndicationRequestVODetails;
