import {
  McModal,
  McButton,
  McIcon,
  McInput,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import react, { useState } from "react";

import { toast } from "react-toastify";
import { postAPI } from "../../../../services/CepApiServices";
interface IPROPS {
  indicateRequestId?: any;
  isModalOpen?: boolean;
  onLoadData?: any;
  onCloseFunc?: any;
  submitData?: any;
  reloadApi?: any;
}
const AddNewRiderClause = ({
  indicateRequestId,
  isModalOpen,
  onLoadData,
  onCloseFunc,
  reloadApi,
}: IPROPS) => {
  const [riderClauseTitle, setRiderClauseTitle] = useState<string>("");
  const [riderClauseValue, setRiderClauseValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);

  const handleSubmit = async () => {
    if (riderClauseTitle.trim() === "") {
      setTitleError(true);
      return;
    }
    if (riderClauseValue.trim() === "") {
      setContentError(true);
      return;
    }
    setIsSubmitting(true);
    const newObjData = {
      indicationRequestId: Number(indicateRequestId),
      title: riderClauseTitle,
      content: riderClauseValue,
    };
    const callSaveAPI = await postAPI(
      `Negotiation/AddTitleContentAsync`,
      newObjData
    );
    if (callSaveAPI.StatusCode) {
      if (callSaveAPI.Message.includes("Title already exists")) {
        toast.error(`Title already exists`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        toast.error(`Failed adding New Rider clause`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
    } else {
      toast.success(`New Rider clause added`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      reloadApi();
    }
    onCloseFunc();
    setIsSubmitting(false);
    setRiderClauseTitle("");
    setRiderClauseValue("");
  };

  const onCancelData = () => {
    onCloseFunc();
    setRiderClauseTitle("");
    setRiderClauseValue("");
  };
  return (
    <McModal
      height="430px"
      width="510px"
      heading={"Add New Rider Clause"}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="header-modal">
        <McTextarea
          data-testid="rider-clause-title"
          label="Title"
          input={(e: any) => {
            setTitleError(false);
            setRiderClauseTitle(e.target.value);
          }}
          value={riderClauseTitle}
          rows={2}
          invalid={titleError}
          errormessage="Please enter title"
        ></McTextarea>
      </div>

      <div>
        <McTextarea
          data-testid="rider-clause-content"
          label="Clause Content"
          input={(e: any) => {
            setContentError(false);
            setRiderClauseValue(e.target.value);
          }}
          value={riderClauseValue}
          rows={6}
          invalid={contentError}
          errormessage="Please enter content"
        ></McTextarea>
      </div>

      <McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label={"Submit"}
        onClick={handleSubmit}
        disabled={isSubmitting}
        data-testid="submit-button"
      ></McButton>
      <McButton
        slot="secondaryAction"
        variant="secondary"
        dialogAction="cancel"
        onClick={onCancelData}
        role="cancel-button"
        fit="small"
      >
        Cancel
      </McButton>
    </McModal>
  );
};
export default AddNewRiderClause;
