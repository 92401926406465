import {
  McModal,
  McButton,
  McTextarea,
  McIcon,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";

interface IPROPS {
  isModalOpen?: boolean;
  isCloseModal?: any;
  infoData?: any;
  onLoadData?:any;
  crId?: any;
}

const AssignRepresentativeModal = ({
  isModalOpen,
  isCloseModal,
  infoData,
  onLoadData,
  crId,
}: IPROPS) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const accessType = [
    { value: "Write", label: "Write" },
    { value: "Read", label: "Read" },
  ];

  const [representatives, setRepresentatives] = useState([{ id: 1 }]);

  const addMoreFunc = () => {
    if (representatives.length >= 5) {
      return;
    }
    setRepresentatives([
      ...representatives,
      { id: representatives.length + 1 },
    ]);
  };

  const deleteRowFunc = (id: number) => {
    setRepresentatives(representatives.filter((rep) => rep.id !== id));
  };

  const onSubmit = (data) => {
    const formattedData = {
      charteringRequestId: Number(crId),
      assignmentId: 0,
      vesselIMO: onLoadData[0].imo,
      openRange: onLoadData[0].openRange,
      openPort: onLoadData[0].openPort,
      cmrAssignmentUserDetails: representatives.map((rep, index) => ({
        cmrAssignmentUserId: 0,
        name: data[`name${index}`],
        email: data[`email${index}`],
        accessType: data[`accessType${index}`][0].value,
      })),
      comment: data.comments,
    };
    infoData(formattedData);
  };

  const onError = (errors, e) => console.log(errors, e);
  const accessTypes = watch(representatives.map((_, index) => `accessType${index}`));

  return (
    <>
      <form
        data-testid="form"
        className="charterMarketAddModal"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <McModal
          height="500px"
          width="900px"
          className="broker-info-modal"
          heading={`Assign Representative`}
          open={isModalOpen}
          backdropcloseactiondisabled
        >
          <div
            className="closeIcon-modal"
            data-testid="close"
            onClick={isCloseModal}
          >
            <McIcon icon="times"></McIcon>
          </div>
          <div className="broker-data">
            {representatives.map((rep, index) => (
              <div key={rep.id} className="broker-data-io details-section">
                <div className="row">
                  <div className="col-md-4">
                    <div className="header space-btw">Name</div>
                    <div className="value">
                      <Controller
                        name={`name${index}`}
                        control={control}
                        defaultValue=""
                        data-testid={`name${index}`}
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                          <input
                            className="inputText"
                            placeholder="Name"
                            {...register(`name${index}`, { required: true })}
                            type="text"
                            role="name"
                          ></input>
                        )}
                      />
                      {errors[`name${index}`] && (
                        <span className="error-message">Name is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="header">
                      E-mail<span className="asterisk">*</span>
                    </div>
                    <div className="value">
                      <Controller
                        name={`email${index}`}
                        control={control}
                        defaultValue=""
                        data-testid={`email${index}`}
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                          <input
                            className="inputText"
                            placeholder="E-mail"
                            {...register(`email${index}`, { required: true })}
                            type="text"
                            role="email"
                          ></input>
                        )}
                      />
                      {errors[`email${index}`] && (
                        <span className="error-message">E-mail is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="header">
                      Access type<span className="asterisk">*</span>
                    </div>
                    <div className="value">
                      <McSelect
                        {...register(`accessType${index}`, { required: true })}
                        name={`accessType${index}`}
                        placeholder="Select"
                        fit="small"
                        label=""
                        options={accessType.map((option) => ({
                          ...option,
                          isDisabled:
                            option.value === "Write" &&
                            accessTypes.includes("Write") &&
                            accessTypes[index] !== "Write",
                        }))}
                        data-testid={`accessType${index}`}
                      ></McSelect>
                      {errors[`accessType${index}`] && (
                        <span className="error-message">Access type is required</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="header"></div>
                    <div className="value thrash-icon">
                      <McIcon
                        data-testid="delete-option"
                        icon="trash"
                        onClick={() => deleteRowFunc(rep.id)}
                      ></McIcon>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="broker-data-io details-section">
              <div className="row">
                <div className="col-md-4">
                  {representatives.length < 5 && (
                    <span className="add-more" onClick={() => addMoreFunc()}>
                      + Add more
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="broker-data-io details-section">
              <div className="value">
                <McTextarea
                  {...register("comments")}
                  name="comments"
                  label="Comments"
                  placeholder="Comment"
                  rows={3}
                  fit="medium"
                  data-testid="comments"
                ></McTextarea>
              </div>
            </div>
          </div>

          <McButton
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
            onClick={isCloseModal}
            role="close"
            fit="small"
          >
            Close
          </McButton>
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            label="Confirm"
            data-testid="confirm"
            fit="small"
          ></McButton>
        </McModal>
      </form>
    </>
  );
};

export default AssignRepresentativeModal;