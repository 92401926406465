export const AppString = {
  deleteModalTxt: "Are you sure you want to delete this document?",
  saveModalTxt: "Are you sure you want to save these details?",
  saveModalIndicationRequestTxt:
    "Are you sure you want to submit this indication?",
  rejectModalTxt: "Do you want to reject this indication?",
  terminateModalTxt: "Do you want to terminate this indication?",
  cancelModalTxt: "Are you sure you want to cancel this indication?",
  acceptModalTxt: "Do you want to accept this indication?",
  reviewModalTxt:
    "Please note that this action is IRREVERSIBLE,the entire content would be Read-Only and no further changes would be possible.Are you sure you want to continue?",
  confirmIssueRecapModalTxt: "Do you want to issue initial recap?",
  confirmAcknldgeVOModalTxt:
    "Do you want to acknowledge the issue recap initiated by Chartering Manager?",
  confirmCleanRecapModalTxt: "Do you want to issue clean recap?",
  confirmLiftSubsModalTxt: "Do you want to Lift the Subs?",
  confirmAcknCleanRecapByVOModalTxt:
    "Do you want to acknowledge the clean recap initiated by Chartering Manager?",
  extractionFailureTxt:"Ensure PDF is in expected format.If yes, please contact the dev team.",
  deleteAdditionalFieldTxt: "Are you sure you want to delete this field?"
};
