import { McModal, McButton, McTextarea, McIcon, McSelect } from "@maersk-global/mds-react-wrapper";
import moment from "moment";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

interface IPROPS {
    isModalOpen?: boolean;
    isCloseModal?: any;
    infoData?: any;
    setEditedCMRDetails?: any;
    allEditedDetails?:any;
}
const EditRepresentativeModal = ({ isModalOpen, isCloseModal, infoData, setEditedCMRDetails, allEditedDetails }: IPROPS) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();
    let allEditDetails = infoData;
    const dataValue = infoData.cmrAssignmentUserDetails;
    const commentValue = infoData.comment;
    const accessType = [
        { value: "Write", label: "Write" },
        { value: "Read", label: "Read" },
    ];

    const onSubmit = (data) => {
        setEditedCMRDetails(data);
        // updatedCMRDetails(editedCMRDetails);
        allEditedDetails(allEditDetails);
        isCloseModal();
    }

    const onError = (errors, e) => console.log(errors, e);

    const chkValueChanged = (e: any, label: string) => {

    }

    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 48,
            minHeight: 38,
        }),
    };

    const onDeleteCMRDetails = (data) => {
        console.log("delete icon clicked" + JSON.stringify(data));
    }

    return (
        <>
            <form data-testid="form" className="charterMarketAddModal" onSubmit={handleSubmit(onSubmit, onError)}>
                <McModal
                    height="500px"
                    width="800px"
                    className="broker-info-modal"
                    heading={`Edit Representative`}
                    open={isModalOpen}
                    backdropcloseactiondisabled
                >
                    <div
                        className="closeIcon-modal"
                        data-testid="close"
                        onClick={isCloseModal}
                    >
                        <McIcon icon="times"></McIcon>
                    </div>
                    <div className="broker-data">
                        {dataValue?.map((data: any, index: any) => {
                            return (
                            <div className="broker-data-io details-section">
                                <div className="row details-row">
                                    <div className="col-md-4">
                                        <div className="header space-btw">Name</div>
                                        <div className="value">
                                            <Controller
                                                name="name"
                                                control={control}
                                                defaultValue={data?.name}
                                                data-testid="name"
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        className="inputText"
                                                        // defaultValue={name}
                                                        // placeholder="Name"
                                                        {...register("name")}
                                                        type="text"
                                                        role="name"
                                                        onChange={(val) => {
                                                            chkValueChanged(val, "name");
                                                        }}
                                                        defaultValue={data?.name}
                                                    ></input>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="header">E-mail</div>
                                        <div className="value">
                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue={data?.email}
                                                data-testid="email"
                                                render={({ field: { onChange } }) => (
                                                    <input
                                                        className="inputText"
                                                        // placeholder="E-mail"
                                                        defaultValue={data?.email}
                                                        {...register("email")}
                                                        type="text"
                                                        role="email"
                                                        onChange={(val) => {
                                                            chkValueChanged(val, "email");
                                                        }}
                                                    ></input>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="header">Access type</div>
                                        <div className="value">
                                            <Controller
                                                control={control}
                                                name="accessType"
                                                data-testid="accessType"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        styles={customStyles}
                                                        defaultValue={{
                                                            value: dataValue[0]?.['accessType'],
                                                            label: dataValue[0]?.['accessType'],
                                                        }}
                                                        options={accessType}
                                                        name="accessType"
                                                        onChange={(val: any) => onChange(val.value)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-1">
                                        <div className="header"></div>
                                        <div className="value thrash-icon">
                                            <McIcon
                                                data-testid="delete-option"
                                                icon="trash"
                                                onClick={() => onDeleteCMRDetails(infoData[0])}
                                            ></McIcon>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            )
                         })}

                        <div className="broker-data-io details-section">
                            <div className="col-md-11 comments-section">
                                <label>Comments</label>
                                {/* <McTextarea
                                    {...register("comments")}
                                    name="comments"
                                    label="Comments"
                                    placeholder="Comment"
                                    rows={3}
                                    fit="medium"
                                    value={dataValue[0]?.comment}
                                    data-testid="comments"
                                ></McTextarea> */}
                                <textarea
                                    data-testid="cm-comments"
                                    className="commentArea"
                                    {...register("comments")}
                                    defaultValue={
                                        commentValue !== null ? commentValue : ""
                                    }
                                    placeholder="comments"
                                    rows={3}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <McButton
                        slot="secondaryAction"
                        variant="secondary"
                        dialogAction="cancel"
                        onClick={isCloseModal}
                        role="cancel-cp"
                        fit="small"
                    >
                        Close
                    </McButton>
                    <McButton
                        slot="primaryAction"
                        variant="primary"
                        type="submit"
                        // disabled={isModalSubmitted}
                        label="Confirm"
                        data-testid="confirm"
                        fit="small"
                    ></McButton>
                </McModal>
            </form>
        </>
    )
}

export default EditRepresentativeModal;