import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./InitiateOfferTable.scss";
import { InitiateOfferModel } from "./Model";
import DataTable from "react-data-table-component";
import { getAPI } from "../../../services/CepApiServices";
import { TableColumn } from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import InitiateOfferModal from "./InitiateOfferModal";
import ExtendContractModal from "./ExtendContractModal";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { StatusList } from "../../../utils/StatusList";
import { getUserRole } from "../../../utils/token";
import WriteAccessModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessModal";
import DirectIndication from "../../../CharterRequest/RequestPages/SendIndicationModal/SendDirectIndication";
import moment from "moment";

interface IPROPS {
  filterData?: any;
}
const InitiateOfferTable = ({ filterData }: IPROPS) => {
  const [tableData, setTableData] = useState([]);
  const [mainData, setMainData] = useState<any>([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [isInitiateModal, setIsInitiateModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [extendModalData, setExtendModalData] = useState<any>();
  const [isExtendModal, setIsExtendModal] = useState(false);
  const [directNegtnModal, setIsDirectNegtnModal] = useState(false);
  const [extendContractRowDetails, setExtendContractRowDetails] =
    useState<any>();
  const [showColumn, setShowColumn] = useState(true);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();

  const history = useNavigate();
  const getInitiateOfferData = async () => {
    setIsTableLoader(true);
    await getAPI(`InitialOffer/GetAcceptedIndicationRequest`).then(
      (response) => {
        setTableData(response);
        setMainData(response);
      }
    );
    setIsTableLoader(false);
  };
  const getUserRoleAsync = async () => {
    const userRole = await getUserRole();
    if (userRole === "Deploymentmanager") {
      setShowColumn(false);
    } else if (userRole === "CharteringManager") {
      setShowColumn(true);
    }
  };
  useEffect(() => {
    getUserRoleAsync();
    getInitiateOfferData();
  }, []);
  const pageReload = () => {
    getInitiateOfferData();
  };
  useEffect(() => {
    if (filterData) {
      console.log(JSON.stringify(filterData));
      if (Object.keys(filterData)?.length > 0) {
        const newTableData = mainData?.filter(function (item: any) {
          if (
            filterData?.statusID?.length > 0 &&
            filterData?.requestItem?.length > 0
          ) {
            return (
              filterData?.statusID?.indexOf(item?.statusId) !== -1 &&
              filterData?.requestItem?.indexOf(item?.requestName) !== -1
            );
          } else if (filterData?.statusID.length > 0) {
            return filterData?.statusID?.indexOf(item?.statusId) !== -1;
          } else if (filterData?.requestItem?.length > 0) {
            return filterData?.requestItem?.indexOf(item?.requestName) !== -1;
          } else {
            return item;
          }
        });
        setTableData(newTableData);
      } else {
        getInitiateOfferData();
      }
    }
  }, [filterData]);
  const OfferStatus = (row: any) => {
    if (row.accessType == "Write" && row.statusId == 6) {
      return (
        <McButton
          data-testid="initiate-button"
          label="Initiate"
          onclick={(e) => handleInitiateOffer(row)}
          fit="small"
        ></McButton>
      );
    } else if (row.accessType == "Read" && row.statusId == 6) {
      return <McButton label="Initiate" fit="small" disabled></McButton>;
    } else {
      return (
        <McButton
          data-testid="view-button"
          label="View"
          onclick={(e) => handleViewOffer(row)}
          fit="small"
        ></McButton>
      );
    }
  };
  const extendContractBtn = (row: any) => {
    if (row.statusId == 17) {
      return (
        <McButton
          data-testid="extend-button"
          label="Extend"
          onclick={(e) => handleExtendContract(row)}
          fit="small"
          disabled={row.isContractExtended == "Yes"}
        ></McButton>
      );
    }
  };
  const handleWriteAssigneeFun = (value: any) => {
    SetIsWriteAccess(true);
    setRowData(value);
  };
  const handleAssignee = (row: any) => {
    if (
      row?.statusId !== 1 &&
      row?.statusId !== 3 &&
      row?.statusId !== 7 &&
      row.accessType === "Write"
    ) {
      return (
        <span
          className="manage-access"
          data-testid="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const handleViewOffer = (row: any) => {
    history(`/InitiateOfferDetails?id=${row.id}`, {
      state: {
        isExtended: false,
        negotiationID: row?.id,
        extendedContractId: row?.extendedContractId,
        indicationStatus: row?.indicationStatus,
      },
    });
  };
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const handleInitiateOffer = (row: any) => {
    setIsInitiateModal(true);
    setModalData(row);
    // history(`/InitiateOfferDetails?id=${row.id}&section=CM`)
  };
  const handleExtendContract = (row: any) => {
    setIsExtendModal(true);
    setIsDirectNegtnModal(false);
    setExtendModalData(row);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const columns: TableColumn<InitiateOfferModel>[] = [
    {
      name: "Status",
      selector: (row) =>
        row.indicationStatus !== null ? row.indicationStatus : "",
      cell: (row) => StatusList(row.statusId, row.indicationStatus, ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row.requestName !== null ? row.requestName.toLowerCase() : "",
      cell: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row.vesselName !== null ? row.vesselName.toLowerCase() : "",
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width: "5%",
    },  
    {
      name: "Chartering Manager",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "CM Representative",
      selector: (row) =>
        row.createdBy !== null ? row.createdBy.toLowerCase() : "",
      cell: (row) => (row.createdBy !== null ? row.createdBy : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        row.responseReceivedFrom !== null
          ? row.responseReceivedFrom.toLowerCase()
          : "",
      cell: (row) =>
        row.responseReceivedFrom !== null ? row.responseReceivedFrom : "",
      sortable: true,
      width: "9%",
    },
    {
      name: "VO Representative",
      selector: (row) =>
        row.responseReceivedFrom !== null
          ? row.responseReceivedFrom.toLowerCase()
          : "",
      cell: (row) =>
        row.responseReceivedFrom !== null ? row.responseReceivedFrom : "",
      sortable: true,
      width: "9%",
    },
    ...((showColumn
      ? [
          {
            name: "Created On",
            selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
            cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
            sortable: true,
            width: "7%",
          },
          // {
          //   name: "Direct Negotiation",
          //   selector: (row) => row.isDirectNegotiation,
          //   sortable: true,
          //   width: "6%",
          // },
          // {
          //   name: "Access Type",
          //   selector: (row) => (row.accessType !== null ? row.accessType : ""),
          //   cell: (row) => accessTypeSetter(row.accessType),
          //   sortable: true,
          //   width: "6%",
          // },
          {
            name: "Access",
            cell: (row) => handleAssignee(row),
            width: "7%",
          },
          {
            name: "",
            cell: (row) => OfferStatus(row),
            width: "5%",
          },
          {
            name: "",
            cell: (row) => extendContractBtn(row),
            width: "6%",
          },
        ]
      : []) as any),
  ];

  const cancelPopup = (e: any) => {
    setIsInitiateModal(false);
  };

  const cancelExtendPopup = (type: any) => {
    setIsExtendModal(false);
    if (type == "Submit") {
      setIsDirectNegtnModal(true);
    } else {
      setIsDirectNegtnModal(false);
    }
  };

  const closeDirectIndicationModal = () => {
    setIsDirectNegtnModal(false);
  };

  return (
    <div className="initialOfferPage">
      <InitiateOfferModal
        isModalOpen={isInitiateModal}
        onLoadData={modalData}
        onCanceled={cancelPopup}
      />
      <ExtendContractModal
        isModalOpen={isExtendModal}
        onLoadData={extendModalData}
        onCanceled={cancelExtendPopup}
      />
      {directNegtnModal && (
        <DirectIndication
          onLoadData={extendModalData}
          isSIRModal={directNegtnModal}
          charterId={extendModalData}
          closeFunc={closeDirectIndicationModal}
          extendContract={true}
        />
      )}
      <div
        className="ir-table-main table-margin2"
        data-testid="negotiation-table-cm"
      >
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={isTableLoader}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        />
        <WriteAccessModal
          isModalOpen={isWriteAccess}
          onCloseFunc={closeFunc}
          rowData={rowData}
          isCm={true}
          reloadPage={pageReload}
        />
      </div>
    </div>
  );
};

export default InitiateOfferTable;